/* eslint-disable complexity */
/* eslint-disable react/jsx-one-expression-per-line */
import { ApolloProvider } from '@apollo/client';
import * as Sentry from '@sentry/nextjs';
import { Avatar, Button, Dropdown, Layout, Menu } from 'antd';
import { Header } from 'antd/lib/layout/layout';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import timezone from 'dayjs/plugin/timezone'; // dependent on utc plugin
import utc from 'dayjs/plugin/utc';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';
import { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AiOutlineArrowLeft, AiOutlineUser } from 'react-icons/ai';

import TransparentButton from '@/atoms/button-transparent';
import DynamicLoader from '@/atoms/dynamic-loader';
import { useApollo } from '@/lib/apollo';

import '../styles/tailwind.css';
import HeaderMenu from '@/molecules/header-menu';
import { useAuth } from '@core/auth/store/auth';
import { getClientLocations } from '@core/locations/actions/getLocations';
import { useLocationsStore } from '@core/locations/store/locations';
import { getHomeSettings, getUISettings } from '@core/settings/actions/getSettings';
import { useSettingsStore } from '@core/settings/store/settings';

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);
dayjs.extend(utc);
dayjs.extend(timezone);

const { Content } = Layout;

const LogoNoSSR = dynamic(() => import('@/atoms/logo'), {
  ssr: false,
  loading: () => <DynamicLoader size="small" />,
});

const MainApp = ({ Component, pageProps }: AppProps) => {
  const [isReadyToBoot, setIsReadyToBoot] = useState(false);
  const [getSettingsLoading, setGetSettingsLoading] = useState(false);
  const router = useRouter();
  const { clientName } = router.query;
  const isWidget = router.pathname.includes('widget');
  const apolloClient = useApollo(pageProps);
  const {
    state: { user },
  } = useAuth();
  const { setSettings, setUISettings, getSettings, resetSettings } = useSettingsStore();
  const { setLocations, getLocations } = useLocationsStore();

  const isHome = useMemo(() => router.pathname === '/home', [router.pathname]);

  const gotoHome = useCallback(() => {
    router.push(`/home?clientName=${clientName}`);
  }, [clientName]);

  useEffect(() => {
    const sessionClientName = sessionStorage.getItem('clientName');
    if (clientName && sessionClientName !== clientName) {
      sessionStorage.setItem('clientName', clientName as string);
    }
    if (clientName) {
      getUISettings(clientName as string)
        .then(({ UISettings }) => {
          setUISettings(UISettings || []);
        })
        .catch((error) => {
          Sentry.captureException(error);
          resetSettings();
        });
    }
    if ((!getSettings() && clientName) || (clientName && sessionClientName !== clientName)) {
      setIsReadyToBoot(false);
      setGetSettingsLoading(true);
      getHomeSettings(clientName as string)
        .then(({ settings }) => {
          setSettings(settings);
          setGetSettingsLoading(false);
          setIsReadyToBoot(true);
        })
        .catch((error) => {
          Sentry.captureException(error);
          setIsReadyToBoot(true);
          setGetSettingsLoading(false);
          resetSettings();
        });
    } else if (!getSettingsLoading && clientName) {
      setIsReadyToBoot(true);
    }
  }, [clientName]);

  useEffect(() => {
    if (!getLocations().length && clientName) {
      getClientLocations(clientName as string)
        .then((locations) => {
          setLocations(locations);
        })
        .catch((e) => {
          console.error(e);
        });
    }

    const { bablic } = window as any;
    if (bablic && bablic.widget) {
      bablic.widget.show();
      bablic.widget.update({
        position: { corner: 0 },
      });
      // change height of widget to be 250px for mobile devices
      try {
        bablic.widget?.on('widget:loaded', () => {
          bablic.widget.setHeight(250);
        });
      } catch (e) {
        console.error(e);
      }
      // change height of widget to be 250px for mobile devices
      if (bablic.widget.on) {
        bablic.widget.on('widget:loaded', () => {
          // change bablic widget top position to be 50px from top of the screen and 10px from right
          bablic.widget.setHeight(250);
        });
      }
    }
  }, [clientName]);

  useEffect(() => {
    process.on('unhandledRejection', (e: Error): void => {
      console.error(e);
    });
    process.on('uncaughtException', (e: Error): void => {
      console.error(e);
    });
    return () => {
      process.removeAllListeners('unhandledRejection');
      process.removeAllListeners('uncaughtException');
    };
  }, []);

  if (!isReadyToBoot) {
    return (
      <div className="flex justify-center items-center h-screen">
        <DynamicLoader size="large" />
      </div>
    );
  }

  return (
    <ApolloProvider client={apolloClient}>
      {isWidget ? (
        <Component {...pageProps} />
      ) : (
        <div
          className="main-scroll flex flex-col h-screen"
          style={{
            width: '100%',
          }}
        >
          <Header className="flex !px-0 md:!px-[50px] items-center justify-center h-[80px] bg-white border-0 border-b-[1px] border-solid">
            <div className="flex mt-1 mr-5 items-center justify-center md:hidden">
              <Button
                type="link"
                onClick={() => {
                  router.back();
                }}
                icon={<AiOutlineArrowLeft size="20px" color="black" />}
              />
            </div>
            <TransparentButton
              onClick={gotoHome}
              className="hover:!bg-transparent h-[80px] !cursor-pointer flex justify-center items-center"
            >
              <LogoNoSSR />
            </TransparentButton>
            {/* TODO refactor header with our own */}
            <HeaderMenu />
            <div className="flex items-center ml-auto mr-5" style={{ fontSize: '12px' }}>
              {user ? (
                <Dropdown
                  overlay={
                    <Menu>
                      <Menu.Item key="0">
                        <Link href="/profile">
                          <a>Profile</a>
                        </Link>
                      </Menu.Item>
                      <Menu.Item key="1">
                        <Link href="/logout">
                          <a>Logout</a>
                        </Link>
                      </Menu.Item>
                    </Menu>
                  }
                  trigger={['click']}
                >
                  <div className="flex items-center justify-center h-[80px] bg-red-200 cursor-pointer">
                    <Avatar style={{ backgroundColor: '#87d068' }} icon={<AiOutlineUser />} src={user?.imageLink} />
                    <span className="ml-2 text-xs">{user?.name}</span>
                  </div>
                </Dropdown>
              ) : (
                <>
                  <Link
                    className="hidden md:flex items-center ml-auto mr-5"
                    href={`https://${clientName}.pickspace.com/#/login`}
                    target="_blank"
                  >
                    <AiOutlineUser size={20} />
                    <span className="ml-2" style={{ fontSize: '20px' }}>
                      Login
                    </span>
                  </Link>
                  <Button
                    className="flex md:hidden h-[80px] justify-center items-center ml-auto "
                    type="link"
                    onClick={() => {
                      window.close();
                    }}
                  >
                    <AiOutlineUser color="black" size={20} />
                    <span className="ml-2" style={{ fontSize: '16px', color: 'black' }}>
                      Login
                    </span>
                  </Button>
                </>
              )}
            </div>
          </Header>
          <Content
            className={`flex flex-1 col-span-3 relative h-full overflow-y-auto no-scrollbar ${
              isHome ? '' : 'mx-0 md:mx-12'
            }`}
          >
            <Component {...pageProps} />
          </Content>
        </div>
      )}
    </ApolloProvider>
  );
};

export default MainApp;
